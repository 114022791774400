import React from "react";
import { Link } from "react-router-dom";


export default function Header() {
    return (
        <header id="bar">


            <div class="container">

                <div class="row">
                    <div class="col-lg-12">
                        <div class="logo-place">
                            <Link to={"/"}>
                                <img src="images/logo.png" /><span>Glancito</span>
                            </Link>
                        </div>




                        <div class="menu-bar">

                            <div id="cssmenu">
                                <ul>


                                    <li ><Link to="/" data-scroll-nav="1"> Features </Link></li>
                                    <li > <Link to={"/pricing"}> Pricing</Link></li>
                                    {/* <li ><Link to={"/about"}> About</Link></li> */}
                                    <li><Link to={"/contact"}> Contact</Link></li>

                                </ul>
                            </div>
                            <div class="act-btns">
                                <ul>
                                    <li><a href="" class="btn-line hide-me">Login</a></li>
                                    <li><a href="" class="btn-theme ">Free Demo</a></li>
                                </ul>
                            </div>
                        </div>






                    </div>
                </div>


            </div>

        </header>
    )
}