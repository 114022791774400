import React, { useEffect } from "react";


export default function Home() {

    return (
        <>
            <div className="container">
                {/* <OwlCarousel items={1} className='owl-theme' loop margin={10} nav>
                    <div className="carousel-item active">
                        <div className="carosuel-caption">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="carousel-left-item">
                                        <h1>Boost Revenue with Exceptional Marketing Tech.</h1>
                                        <div className="sep-lt" />
                                        <h3>
                                            Leverage automation to streamline your business messaging
                                            strategy and create an outstanding customer experience.
                                        </h3>
                                        <div className="action-btns">
                                            <ul>
                                                <li>
                                                    <a href="" className="btn-theme-big">
                                                        Get a Demo
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="" className="btn-theme-big2">
                                                        Get Started Free
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="carousle-right-item">
                                        <div className="car-image">
                                            <img src="images/slide-01.png" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="carosuel-caption">
                            <div className="row">
                                <div className="col-lg-6 col-md-7 col-sm-12 ">
                                    <div className="carousle-right-item">
                                        <div className="car-image2">
                                            <img src="images/slide-02.png" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-5 col-sm-12">
                                    <div className="carousel-left-item">
                                        <h1>Increase Sales with Exceptional Customer Experience</h1>
                                        <div className="sep-lt" />
                                        <h3>
                                            Increase brand awareness and customer loyalty by
                                            leveraging a unified messaging strategy across Email, SMS,
                                            and Social Media channels to strengthen engagement and
                                            retention.
                                        </h3>
                                        <div className="action-btns">
                                            <ul>
                                                <li>
                                                    <a href="" className="btn-theme-big">
                                                        Get a Demo
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="" className="btn-theme-big2">
                                                        Get Started Free
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </OwlCarousel> */}
                <div className="main-slider ">
                    <div
                        id="carouselExampleIndicators"
                        className="carousel  custom-slider"
                        data-ride="carousel"
                    >
                        <ol className="carousel-indicators">
                            <li
                                data-target="#carouselExampleIndicators"
                                data-slide-to={0}
                                className="active"
                            />
                            <li data-target="#carouselExampleIndicators" data-slide-to={1} />
                        </ol>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className="carosuel-caption">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="carousel-left-item">
                                                <h1>Boost Revenue with Exceptional Marketing Tech.</h1>
                                                <div className="sep-lt" />
                                                <h3>
                                                    Leverage automation to streamline your business messaging
                                                    strategy and create an outstanding customer experience.
                                                </h3>
                                                <div className="action-btns">
                                                    <ul>
                                                        <li>
                                                            <a href="" className="btn-theme-big">
                                                                Get a Demo
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="" className="btn-theme-big2">
                                                                Get Started Free
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                            <div className="carousle-right-item">
                                                <div className="car-image">
                                                    <img src="images/slide-01.png" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="carosuel-caption">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-7 col-sm-12 ">
                                            <div className="carousle-right-item">
                                                <div className="car-image2">
                                                    <img src="images/slide-02.png" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-5 col-sm-12">
                                            <div className="carousel-left-item">
                                                <h1>Increase Sales with Exceptional Customer Experience</h1>
                                                <div className="sep-lt" />
                                                <h3>
                                                    Increase brand awareness and customer loyalty by
                                                    leveraging a unified messaging strategy across Email, SMS,
                                                    and Social Media channels to strengthen engagement and
                                                    retention.
                                                </h3>
                                                <div className="action-btns">
                                                    <ul>
                                                        <li>
                                                            <a href="" className="btn-theme-big">
                                                                Get a Demo
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="" className="btn-theme-big2">
                                                                Get Started Free
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a
                            className="carousel-control-prev"
                            href="#carouselExampleIndicators"
                            role="button"
                            data-slide="prev"
                        >
                            <span className="carousel-control-prev-icon" aria-hidden="true" />
                            <span className="sr-only">Previous</span>
                        </a>
                        <a
                            className="carousel-control-next"
                            href="#carouselExampleIndicators"
                            role="button"
                            data-slide="next"
                        >
                            <span className="carousel-control-next-icon" aria-hidden="true" />
                            <span className="sr-only">Next</span>
                        </a>
                    </div>
                </div>
            </div>
            <section className="sec-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="sec-heading content-center">
                                <h4>
                                    Leave your Marketing Strategy to us and Focus on your Core
                                    Business
                                </h4>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="m-features">
                                <div className="feat-icon">
                                    <img src="images/per-market.png" />
                                </div>
                                <div className="feat-head">Personalized Marketing</div>
                                <div className="feat-desc">
                                    <p>
                                        Utilize the segmentation and reporting capabilities of the
                                        platform to create personalized content tailored to buyer
                                        personas.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="m-features">
                                <div className="feat-icon">
                                    <img src="images/lead.png" />
                                </div>
                                <div className="feat-head">Lead Nurturing</div>
                                <div className="feat-desc">
                                    <p>
                                        Boost your lead nurturing strategy by creating drip email
                                        campaigns and tracking their progress
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="m-features">
                                <div className="feat-icon">
                                    <img src="images/analytics.png" />
                                </div>
                                <div className="feat-head">Valuable Insights</div>
                                <div className="feat-desc">
                                    <p>
                                        Simplify outreach and increase your business value with CRM
                                        integration.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section data-scroll-index={1} className="bottom-padless">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="sec-heading content-center">
                                <h3>Transform your engagement ideas into reality</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li className="nav-item custom-tabs" role="presentation">
                        <button
                            className="nav-link active"
                            id="pills-home-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-1"
                            type="button"
                            role="tab"
                            aria-controls="pills-home"
                            aria-selected="true"
                        >
                            Automation
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-link"
                            id="pills-profile-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-2"
                            type="button"
                            role="tab"
                            aria-controls="pills-profile"
                            aria-selected="false"
                        >
                            Real-Time Customer 360{" "}
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-link"
                            id="pills-contact-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-3"
                            type="button"
                            role="tab"
                            aria-controls="pills-contact"
                            aria-selected="false"
                        >
                            Messaging &amp; Email{" "}
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-link"
                            id="pills-contact-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-4"
                            type="button"
                            role="tab"
                            aria-controls="pills-contact"
                            aria-selected="false"
                        >
                            Reporting &amp; Analytics{" "}
                        </button>
                    </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                    <div
                        className="tab-pane fade show active"
                        id="pills-1"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                    >
                        <div className="tab-body">
                            <div className="row">
                                <div className="col-lg-5 col-md-5 col-sm-12 ">
                                    <div className="tab-head">
                                        <div className="small-head">Marketing Automation</div>
                                        <h3>
                                            Let our smart automation take care of the specifics while you
                                            focus on important stuff
                                        </h3>
                                        <div className="sep-l" />
                                    </div>
                                    <div className="tab-desc">
                                        <p>
                                            Automate your entire marketing process to target, build,
                                            execute and measure the success of ongoing messaging
                                            throughout the full customer journey. Smart marketers rely on
                                            Loyalty Dish solutions to automate their entire marketing
                                            process, allowing them to better align their messaging.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-7 col-md-7 col-sm-12 ">
                                    <div className="tab-image">
                                        <img src="images/marketing-automation.png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="tab-pane fade"
                        id="pills-2"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                    >
                        <div className="tab-body">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="tab-head">
                                        <div className="small-head">Single View of Customer</div>
                                        <h3>Unified view of customer life cycle</h3>
                                        <div className="sep-l" />
                                    </div>
                                    <div className="tab-desc">
                                        <p>Map your customer's life cycle using birds-eye view.</p>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="sec-margins">
                                        <p>
                                            Bid farewell to switching between multiple programs just to
                                            view your automation performance. Now get access to everything
                                            in one place from scheduling to deliverability and automation
                                            along with a birds-eye view of your entire customer lifecycle.
                                        </p>
                                    </div>
                                    <p />
                                </div>
                            </div>
                        </div>
                        <div className="dashboard-carousels">
                            <div className="carousel-content">
                                <div className="carousel-body1">
                                    <div className="owl-carousel owl-theme dashboard-view">
                                        <div className="item dashboard-image">
                                            <img src="images/dasboard-01.png" />
                                        </div>
                                        <div className="item dashboard-image">
                                            <img src="images/dasboard-03.png" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sec-half-bg" />
                        </div>
                    </div>
                    <div
                        className="tab-pane fade"
                        id="pills-3"
                        role="tabpanel"
                        aria-labelledby="pills-contact-tab"
                    >
                        <div className="tab-body">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="tab-head">
                                        <div className="small-head">
                                            Messaging &amp; Email Marketing
                                        </div>
                                        <h3>Tailored Messaging Systems</h3>
                                        <div className="sep-l" />
                                    </div>
                                    <div className="tab-desc">
                                        <p>
                                            Sending tailored messages at scale is an essential aspect of
                                            customer acquisition strategy.{" "}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="sec-margins">
                                        <p>
                                            Build, track and execute marketing messaging using Glancito
                                            intuitive tools and create marketing systems that are tailored
                                            to each business rather than relying on a generalized
                                            marketing system that may not be the right fit
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-5">
                                    <div className="intro-section">
                                        <div className="intro-box">
                                            <div className="intro-head">
                                                <h4>Multi-Channel Campaigns</h4>
                                            </div>
                                            <div className="intro-desc">
                                                <p>
                                                    Boost revenue potential and create an exceptional customer
                                                    experience by combining multiple channels under a single
                                                    platform.{" "}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="msg-desc">
                                        <div className="msg-head">
                                            <h4>
                                                <i className="fa fa-envelope" />
                                                Email
                                            </h4>
                                        </div>
                                        <div className="msg-features">
                                            <ul>
                                                <li>Email marketing automation solution made simple.</li>
                                                <li>
                                                    Discover full email marketing automation possibilities
                                                    with our templates, segmentation, and automated email
                                                    workflows.{" "}
                                                </li>
                                                <li>
                                                    Send professional-quality emails with a drag &amp; drop
                                                    email editor, templates and content blocks that go beyond
                                                    boring promotional messages.{" "}
                                                </li>
                                                <li>Integrating with popular e-commerce platforms. </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="owl-carousel owl-theme message-slides">
                                        <div className="item animated fadeIn item1">
                                            <img src="images/workflow-1.png" />{" "}
                                        </div>
                                        <div className="item animated fadeIn item2">
                                            <img src="images/workflow-2.png" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="msg-desc">
                                        <div className="msg-head">
                                            <h4>
                                                <i className="fa fa-sms" />
                                                SMS
                                            </h4>
                                        </div>
                                        <div className="msg-features">
                                            <p>
                                                Incorporate SMS into your automation workflows or use it as
                                                part of segmented, one-off campaigns.{" "}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="msg-desc">
                                        <div className="msg-head">
                                            <h4>
                                                <i className="fa fa-bell" />
                                                Push Notifications{" "}
                                            </h4>
                                        </div>
                                        <div className="msg-features">
                                            <p>
                                                Get the most out of your automated flow or campaign by
                                                adding push notifications to the automated flow or campaign
                                                for a totally new way to activate customers.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="tab-pane fade"
                        id="pills-4"
                        role="tabpanel"
                        aria-labelledby="pills-contact-tab"
                    >
                        <div className="tab-body">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="tab-head">
                                        <div className="small-head">Reporting &amp; Analytics</div>
                                        <h3>Valuable Insights To Improve Strategy</h3>
                                        <div className="sep-l" />
                                    </div>
                                    <div className="tab-desc">
                                        <p>
                                            Leverage actionable insights to identify successful strategies
                                            and double down on them.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="sec-margins">
                                        <p>
                                            Analyze and compare metrics within no time with comprehensive
                                            engagement reports for each of your campaigns and automation.
                                            Prioritize and tailor your retention strategies to the most
                                            valuable customers to increase the number of repeat purchases
                                            and keep your brand at the forefront of consumers' minds.
                                        </p>
                                    </div>
                                    <p />
                                </div>
                            </div>
                        </div>
                        <div className="dashboard-carousels">
                            <div className="carousel-content">
                                <div className="carousel-body">
                                    <div className="owl-carousel owl-theme dashboard-view">
                                        <div className="item dashboard-image">
                                            <div className="sec-captions">
                                                <div className="row">
                                                    <div className="col-lg-4">
                                                        <h3>Easy-To-Read Dashboard</h3>
                                                    </div>
                                                    <div className="col-lg-8">
                                                        <p>
                                                            Easily keep track of the sales that are produced by
                                                            all campaigns and automation and make a handy
                                                            comparison to your overall revenue figures to get a
                                                            complete picture.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <img src="images/dasboard-01.png" />
                                        </div>
                                        <div className="item dashboard-image">
                                            <div className="sec-captions">
                                                <div className="row">
                                                    <div className="col-lg-4">
                                                        <h3>Performance Reports</h3>
                                                    </div>
                                                    <div className="col-lg-8">
                                                        <p>
                                                            Glancito provides each of your customers with a
                                                            lifecycle stage, which is displayed in an easy-to-read
                                                            lifecycle stage map.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <img src="images/dasboard-03.png" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sec-half-bg3" />
                            <div className="sec-half-bg2" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="integration sec-bg" data-scroll-index={2}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="tab-head">
                                <div className="small-head">Integration</div>
                                <h3>Discover 30+ apps &amp; boost your sales</h3>
                                <div className="sep-l" />
                                <div className="act-btns">
                                    <a href="" className="btn-theme">
                                        Get a Demo
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-6 col-sm-12">
                            <div className="sec-margin-mob">
                                <p>
                                    Extend the power of email &amp; SMS with apps that easily
                                    integrate into your tech stack. Get more sales, in more ways, and
                                    build better customer loyalty.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="integration-illustration">
                                <img src="images/integration.png" className="img-large-device" />
                                <img src="images/integration2.png" className="img-small-device" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}