import React from "react";

export default function Contact() {
    return (
        <div className="page-bg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 ">
                        <h3>Contact Us</h3>
                        <div className="sep-l" />
                        <p>
                            Get in touch with the right person or team by choosing what kind of
                            assistance you’re looking for.
                        </p>
                    </div>
                    <div className="col-lg-4">
                        <a href="">
                            <div className="c-box">
                                <div className="c-head">
                                    <span>Get help using Glancito</span>
                                    Support
                                </div>
                                <div className="c-desc">
                                    <p>
                                        Have questions about our features, or want to find out more
                                        about our product ?
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-lg-4">
                        <a href="">
                            <div className="c-box">
                                <div className="c-head">
                                    <span>Learn more about Glancito</span>
                                    Sales
                                </div>
                                <div className="c-desc">
                                    <p>
                                        Need a personalized demo of our product or a special offer for
                                        your business?
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-lg-4">
                        <a href="">
                            <div className="c-box">
                                <div className="c-head">
                                    <span>Team up with Glancito</span>
                                    Partnerships
                                </div>
                                <div className="c-desc">
                                    <p>
                                        Interested in a technology partnership, becoming an affiliate,
                                        or have some other partnership ideas?
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div className="contact-us-form">
                <form>
                    <div className="container">
                        <div className="form-box">
                            <div className="row">
                                <div className="col-lg-12 ">
                                    <h4>Write to Us !</h4>
                                    <div className="sep-l" />
                                    <p>
                                        Whether you’re in the UK, USA or Australia, there’ll always be
                                        someone around to help. Thanks to our global office network you
                                        can ask us anything, anytime.
                                    </p>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">First Name</label>
                                        <input
                                            type="text"
                                            className="form-control custom-control"
                                            id="f-name"
                                            aria-describedby="firstname"
                                            placeholder="Enter Your First Name"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Last Name</label>
                                        <input
                                            type="text"
                                            className="form-control custom-control"
                                            id="f-name"
                                            aria-describedby="firstname"
                                            placeholder="Enter Your Last Name"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Business Email</label>
                                        <input
                                            type="text"
                                            className="form-control custom-control"
                                            id="f-name"
                                            aria-describedby="firstname"
                                            placeholder="Enter Your Business Email Address"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Business Phone</label>
                                        <input
                                            type="text"
                                            className="form-control custom-control"
                                            id="f-name"
                                            aria-describedby="firstname"
                                            placeholder="Enter Your Business Phone Number"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlTextarea1">
                                            Your Message
                                        </label>
                                        <textarea
                                            className="form-control custom-control-area"
                                            id="exampleFormControlTextarea1"
                                            placeholder="Enter Your Message Here"
                                            rows={6}
                                            defaultValue={""}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="act-btn">
                                        <button type="submit" className="btn-theme">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    )
}