import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <footer>
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-sm-12">
                        <h2 class="f-title white-text">Get Started Today and
                            Drive your sales impact
                            with Glancito</h2>
                        <div class="sep-lf"></div>
                    </div>
                    <div class="col-lg-6 col-sm-12"><p class="white-text big-p">Leverage automation to streamline your business messaging strategy and create an outstanding customer experience. </p></div>

                    <div class="col-lg-6 col-sm-12">
                        <div class="fact-btns">
                            <ul>
                                <li><a href="" class="btn-theme-big">Start Your Free Trail</a></li>
                                <li> <a href="" class="btn-line-white">Sign up for free</a></li>
                            </ul>


                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                        <div class="row">
                            <div class="col-lg-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <div class="f-list">
                                    <ul>
                                        <li><a href="">About</a></li>
                                        <li><a href="">Features</a></li>
                                        <li><a href="">Integration</a></li>


                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <div class="f-list">
                                    <ul>
                                        <li><a href="">Pricing</a></li>
                                        <li><a href="">Contact Us</a></li>
                                        <li><a href="">FAQs</a></li>

                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <div class="f-list">
                                    <ul>


                                        <li><a href="">Get a Demo</a></li>
                                        <li><a href="">Free Sign Up</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>




                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12">
                        <div class="footer-logo">
                            <img src="images/logo-big.png" />
                                <span>Glancito</span>

                                <div class="rights">© 2023 Glancito. All rights reserved.</div>
                                <div class="terms"><Link to="/privacy">Terms & Privacy</Link></div>
                                <div class="social-icons">
                                    <ul>
                                        <li><a href=""><i class="fab fa-facebook"></i></a></li>
                                        <li><a href=""><i class="fab fa-twitter"></i></a></li>
                                    </ul>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    )
}