import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom"
import $ from "jquery";
import Header from './components/header';
import './App.css';
import Footer from './components/footer';
import Home from './pages/home';
import Contact from "./pages/contact";
import Pricing from "./pages/pricing";
import Privacy from "./pages/privacy";



function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  useEffect(() => {

    window.$('.dashboard-view').owlCarousel({
      autoplay: true,
      mouseDrag: true,
      responsiveClass: true,
      autoplayTimeout: 1000,
      nav: false,
      animateOut: 'slideOutRight',
      animateIn: 'slideInLeft',
      responsive: {
        0: {
          items: 1,
          nav: false,
          dots: true
        },

      }
    })

    window.$('.message-slides').owlCarousel({
      loop: true,
      margin: 70,
      autoplay: true,
      mouseDrag: true,
      responsiveClass: true,
      nav: true,
      dots: false,

      responsive: {
        0: {
          items: 1


        },

      }
    })



    window.$('#bar').scrollFix();
    $(window).on("scroll", function () {
      if ($(window).scrollTop() > 100) {
        window.$("header").addClass("myheader");
      } else {
        window.$("header").removeClass("myheader");
      }
    });


    window.$.scrollIt({
      upKey: 38,             // key code to navigate to the next section
      downKey: 40,           // key code to navigate to the previous section
      easing: 'linear',      // the easing function for animation
      scrollTime: 600,       // how long (in ms) the animation takes
      activeClass: 'active', // class given to the active nav element
      onPageChange: null,    // function(pageIndex) that is called when page is changed
      topOffset: -80           // offste (in px) for fixed top navigation
    });

  }, [])
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
